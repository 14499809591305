<template>
  <div class="bg-white">
    <div class="flex items-center justify-between p-5 h-20 container mx-auto font-['Fira_Mono'] ">
      <div class="flex items-center gap-3 text-xl">
        <div class="font-medium ">
          vicho.dev
        </div>
      </div>
      <div class="hidden">
        <div>
          Timeline
        </div>
        <div>
          Projects
        </div>
        <div>
          Contact
        </div>
      </div>
    </div>
  </div>
</template>
